<template>
  <div class="v-responsive v-image" style="min-height: 100%">
    <div class="v-responsive__content" style="width: 100%">
      <div class="v-responsive d-flex align-center" style="min-height: 100vh; padding: 175px 0px">
        <div class="v-responsive__content ma-auto">
          <section class="container fill-height justify-center" id="login">
            <div class="row justify-center">
              <div
                class="v-card--material pa-3 px-5 py-3 v-card v-sheet theme--light v-card--material--has-heading"
                light
                max-width="100%"
                width="400"
                style="max-width: 100%; width: 400px; transform-origin: center top 0"
              >
                <div class="d-flex grow flex-wrap">
                  <div class="text-start v-card--material__heading mb-n6 v-sheet elevation-6 success pa-7" style="width: 100%">
                    <div class="text-center">
                      <h1 class="display-2 font-weight-bold mb-2">Вход</h1>
                    </div>
                  </div>
                </div>

                <form class="v-card__text text-center mt-10" @submit.prevent="doLogin">
                  <v-text-field
                    name="email"
                    label="Email"
                    inputmode="email"
                    prepend-icon="mdi-email"
                    v-model="form.email"
                  ></v-text-field>
                  <v-text-field
                    type="password"
                    required
                    label="Password"
                    prepend-icon="mdi-email"
                    v-model="form.password"
                  ></v-text-field>

                  <v-btn type="submit" class="mt-4" rounded depressed color="success" :loading="isFetch">Войти</v-btn>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      isFetch: false,
      form: {
        email: null,
        password: null,
      },
    };
  },

  methods: {
    async doLogin() {
      this.isFetch = true;
      const result = await this.$store.dispatch("auth/login", this.form);
      this.isFetch = false;

      if (!result.success) {
        alert(result.message);
        return;
      }

      await this.$router.push({ path: this.$route.query.redirect ?? "/" });
    },
  },
};
</script>

<style></style>
